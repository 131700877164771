import { WidgetRendererProps } from '@app/dashboard/biolink/biolink-editor/content/widgets/types/widget-renderer-props';
import { MapWidget } from './MapWigetDialog';
import { useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { LocationOnIcon } from '@common/icons/material/LocationOn';

export function MapWidgetRendered({
    widget,
    variant,
}: WidgetRendererProps<MapWidget>) {
    if (variant === 'editor') {
        return <></>;
    }

    const [location, setLocation] = useState(['-7.966720281161102', '112.6216049194335']);

    useEffect(() => {
        if (widget.config?.geolocation !== undefined) {
            const getLocation = widget.config?.geolocation?.split('|');
            setLocation([getLocation[0], getLocation[1]]);
        }
    }, [widget.config?.geolocation])

    return (
        <div className='px-12'>
            {widget.config?.geolocation && <iframe width="100%" height="150" src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDn5TU07R040YBRD-9ePpM8Noh-Z1NNVyw&q=${location[0]},${location[1]}`}></iframe>}
            {widget.config?.address && (
                <Paper className='flex flex-row mt-5 p-5'>
                    <LocationOnIcon color='red' />
                    <Typography variant='subtitle2'>{widget.config?.address}</Typography>
                </Paper>
            )}
        </div>
    );
}