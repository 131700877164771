import {JSXElementConstructor} from 'react';
import {ImageWidgetRenderer} from '@app/dashboard/biolink/biolink-editor/content/widgets/image-widget/image-widget-renderer';
import {WidgetRendererProps} from '@app/dashboard/biolink/biolink-editor/content/widgets/types/widget-renderer-props';
import {YoutubeWidgetRenderer} from '@app/dashboard/biolink/biolink-editor/content/widgets/youtube-widget/youtube-widget-renderer';
import {TextWidgetRenderer} from '@app/dashboard/biolink/biolink-editor/content/widgets/text-widget/text-widget-renderer';
import {SocialsWidgetRenderer} from '@app/dashboard/biolink/biolink-editor/content/widgets/socials-widget/socials-widget-renderer';
import {TwitchWidgetRenderer} from '@app/dashboard/biolink/biolink-editor/content/widgets/twitch-widget/twitch-widget-renderer';
import {SoundcloudWidgetRenderer} from '@app/dashboard/biolink/biolink-editor/content/widgets/soundcloud-widget/soundcloud-widget-renderer';
import {VimeoWidgetRenderer} from '@app/dashboard/biolink/biolink-editor/content/widgets/vimeo-widget/vimeo-widget-renderer';
import {SpotifyWidgetRenderer} from '@app/dashboard/biolink/biolink-editor/content/widgets/spotify-widget/spotify-widget-renderer';
import {TiktokWidgetRenderer} from '@app/dashboard/biolink/biolink-editor/content/widgets/tiktok-widget/tiktok-widget-renderer';
import {WidgetType} from '@app/dashboard/biolink/biolink-editor/content/widgets/types/widget-type';
import { SpaceWidgetRenderer } from './space/space-widget-renderer';
import { CountdownWidgetRenderer } from './countdown/countdown-widget-renderer';
import { TextEditorWidgetRenderer } from './text-editor-widget/text-widget-renderer';
import { AddCalendarWidgetRender } from './add-calender-widget/AddCalenderReder';
import { MapWidgetRendered } from './map-widget/MapWidgetRendered';
import { HeaderWidgetRenderer } from './header/header-renderer';

export const WidgetRenderers: Record<
  WidgetType,
  JSXElementConstructor<WidgetRendererProps<any>>
> = {
  [WidgetType.Image]: ImageWidgetRenderer,
  // [WidgetType.Text]: TextWidgetRenderer,
  [WidgetType.RichText]: TextEditorWidgetRenderer,
  [WidgetType.Socials]: SocialsWidgetRenderer,
  [WidgetType.Youtube]: YoutubeWidgetRenderer,
  [WidgetType.Header]: HeaderWidgetRenderer,
  [WidgetType.Soundcloud]: SoundcloudWidgetRenderer,
  [WidgetType.Vimeo]: VimeoWidgetRenderer,
  [WidgetType.Spotify]: SpotifyWidgetRenderer,
  [WidgetType.Twitch]: TwitchWidgetRenderer,
  [WidgetType.Tiktok]: TiktokWidgetRenderer,
  [WidgetType.AddCalendar]: AddCalendarWidgetRender,
  // [WidgetType.Accordions]: TiktokWidgetRenderer,
  [WidgetType.Space]: SpaceWidgetRenderer,
  [WidgetType.Countdown]: CountdownWidgetRenderer,
  [WidgetType.Map]: MapWidgetRendered
};
