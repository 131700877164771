import React, { useState } from 'react';
import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material';

import GoogleIcon from '@mui/icons-material/Google';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AppleIcon from '@mui/icons-material/Apple';

export interface EventInterface {
    title: string;         
    description: string;  
    location: string;     
    startDate: string;     
    endDate: string;       
}

interface AddToCalendarDropdownProps {
    event: EventInterface;
}

const AddToCalendarDropdown: React.FC<AddToCalendarDropdownProps> = ({ event }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { title, description, location, startDate, endDate } = event;

    const open = Boolean(anchorEl);

    const handleClick = (event : any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Format date for Google Calendar (ISO 8601)
    const formatDate = (date : string) => new Date(date).toISOString().replace(/[-:]|(\.\d{3})/g, '');

    // Links for calendars
    const googleLink = `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(
        title
    )}&details=${encodeURIComponent(description)}&location=${encodeURIComponent(
        location
    )}&dates=${formatDate(startDate)}/${formatDate(endDate)}`;

    const outlookLink = `https://outlook.live.com/owa/?path=/calendar/action/compose&subject=${encodeURIComponent(
        title
    )}&body=${encodeURIComponent(description)}&location=${encodeURIComponent(
        location
    )}&startdt=${encodeURIComponent(new Date(startDate).toISOString())}&enddt=${encodeURIComponent(
        new Date(endDate).toISOString()
    )}`;

    const downloadAppleCalendar = () => {
        const icsData = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${title}
DESCRIPTION:${description}
LOCATION:${location}
DTSTART:${formatDate(startDate)}
DTEND:${formatDate(endDate)}
END:VEVENT
END:VCALENDAR`;
        const blob = new Blob([icsData], { type: 'text/calendar;charset=utf-8' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${title}.ics`;
        link.click();
    };

    return (
        <div>
            {/* Dropdown Button */}
            <Button
                variant="contained"
                onClick={handleClick}
                aria-controls={open ? 'add-to-calendar-menu' : undefined}
                aria-haspopup="true"
                color='inherit'
                aria-expanded={open ? 'true' : undefined}
                startIcon={<EditCalendarIcon />}
            >
                Add to Calendar
            </Button>

            {/* Dropdown Menu */}
            <Menu
                id="add-to-calendar-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'add-to-calendar-button',
                }}
            >
                <MenuItem
                    component="a"
                    href={googleLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClose}
                >
                    <ListItemIcon>
                        <GoogleIcon fontSize="small" />
                    </ListItemIcon>
                    Google Calendar
                </MenuItem>
                {/* <MenuItem
                    component="a"
                    href={outlookLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClose}
                >
                    Outlook Calendar
                </MenuItem> */}
                <MenuItem
                    onClick={() => {
                        downloadAppleCalendar();
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <AppleIcon fontSize="small" />
                    </ListItemIcon>
                    Apple Calendar (.ics)
                </MenuItem>
            </Menu>
        </div>
    );
};

export default AddToCalendarDropdown;
