import {ImageWidget} from '@app/dashboard/biolink/biolink-editor/content/widgets/image-widget/image-widget-dialog';
import {WidgetRendererProps} from '@app/dashboard/biolink/biolink-editor/content/widgets/types/widget-renderer-props';
import clsx from 'clsx';
import {ImageIcon} from '@common/icons/material/Image';
import { SpaceWidget } from './space-widget-dialog';

export function SpaceWidgetRenderer({
  widget,
  variant,
}: WidgetRendererProps<SpaceWidget>) {
  if (variant === 'editor') {
    return <></>;
  }

  const containerStyle = {
    height: `${widget.config.space}px`,
  };

  return (
    <div style={containerStyle}>
      {/* Your content here */}
    </div>
  );
}