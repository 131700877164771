import {ImageWidget} from '@app/dashboard/biolink/biolink-editor/content/widgets/image-widget/image-widget-dialog';
import {WidgetRendererProps} from '@app/dashboard/biolink/biolink-editor/content/widgets/types/widget-renderer-props';
import clsx from 'clsx';
import {ImageIcon} from '@common/icons/material/Image';
import { CountdownWidget } from './countdown-widget-dialog';
import { useEffect, useState } from 'react';
import './style.css';

export function CountdownWidgetRenderer({
  widget,
  variant,
}: WidgetRendererProps<CountdownWidget>) {
  const [timeLeft, setTimeLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (variant !== 'editor') {
      const endTime = widget?.config?.time ? new Date(widget.config.time).getTime() : 0;
      
      const countdownInterval = setInterval(() => {
        const now = new Date().getTime();
        const distance = endTime - now;
        
        // Calculate days, hours, minutes, and seconds from distance
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
        // Format countdown display
        setTimeLeft(distance > 0 ? distance : 0);
        
        // Clear interval if countdown is finished
        if (distance <= 0) {
          clearInterval(countdownInterval);
        }
      }, 1000);

      // Clear interval on component unmount
      return () => clearInterval(countdownInterval);
    }
  }, [variant, widget.config.time]);

  if (variant === 'editor') {
    return <div>Date time : {widget.config.time}</div>;
  }

    let days = 0, hours = 0, minutes = 0, seconds = 0;

    if (timeLeft !== undefined) {
      days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    }

    return (
      <div className={clsx('countdown-widget')}>
        <div className="countdown-item">
          <span className="countdown-value">{days}</span>
          <span className="countdown-label">Hari</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-value">{hours}</span>
          <span className="countdown-label">Jam</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-value">{minutes}</span>
          <span className="countdown-label">Menit</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-value">{seconds}</span>
          <span className="countdown-label">Detik</span>
        </div>
      </div>
    );
}
