import { widget_image_name } from "../image-widget/image-widget-config";
import { widget_space_name } from "../space/space-confix";

export enum WidgetType {
  Image = 'image',
  // Text = 'text',
  AddCalendar = 'add-calendar',
  RichText = 'text',
  Socials = 'socials',
  Youtube = 'youtube',
  Soundcloud = 'soundcloud',
  Vimeo = 'video',
  Spotify = 'spotify',
  Twitch = 'twitch',
  Tiktok = 'tiktok',
  // Accordions = 'accordions',
  Space = 'space',
  Countdown = 'countdown',
  Map = 'map',
  Header = 'header',
}