import { Avatar, Button, IconButton, Typography } from "@mui/material";
import { HeaderVarianInterface, HeaderVariant } from "../header-varian";
import { useContext, useEffect } from "react";
import { useCompactLayout } from "@app/hooks/BiolinkPageHooks";
import { useIsMobileMediaQuery } from "@common/utils/hooks/is-mobile-media-query";

export interface HeaderCenterConfig extends HeaderVarianInterface {
    config: {
        'avatar': string,
        'title': string,
        'description': string,
        'backgroundType': string,
        'backgroundColor': string,
        'backgroundImage': string
    },
    variant: string
}

function HeaderConfigRenderMobile(props: HeaderCenterConfig) {
    return <div 
        className="z-20 min-w-[600px] hidden md:block bg-white rounded-s-lg"
        style={{
            // boxShadow: "rgba(0, 0, 0, 0.2) -15px 0px 15px 0px, rgba(0, 0, 0, 0.2) 0px -15px 15px 0px, rgba(0, 0, 0, 0.2) 0px 15px 15px 0px"
        }}
        >
        <header
            style={{
                backgroundColor: props.config.backgroundColor,
                backgroundImage:
                    props.config.backgroundType === "image"
                        ? `url(${props.config.backgroundImage})`
                        : "",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "65vh",
                position: "relative",
            }}
            className="rounded-tl-md"
        >
            <img
                src={props.config.avatar}
                alt={props.config.title}
                style={{
                    width: "110px",
                    backgroundColor: "#fff",
                    height: "110px",
                    borderRadius: "50%",
                    border: "4px solid white",
                    position: "absolute",
                    bottom: "-60px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
            />
        </header>

        {/* Title and Description */}
        <div
            style={{
                textAlign: "center",
                paddingTop: "70px"
            }}
        >
            <Typography variant="h5" sx={{
                fontWeight: 600,
            }}>
                {props.config.title}
            </Typography>
            <Typography variant="subtitle1" sx={{
                fontWeight: 80
            }}>
                {props.config.description}
            </Typography>
        </div>
    </div>
}

export default function HeaderConfigRender(props: HeaderCenterConfig) {
    const isMobile = useIsMobileMediaQuery();

    try {
        const { sidebar, setSidebar } = useCompactLayout();

        useEffect(() => {
            setSidebar(<HeaderConfigRenderMobile {...props} />);
        }, []);

        if (!isMobile) {
            return <></>
        }
    } catch (error) {
        console.log("editor mode")
    }

    return (
        <div>
            <header
                className="h-[150px]"
                style={{
                    backgroundColor: props.config.backgroundColor,
                    backgroundImage:
                        props.config.backgroundType === "image"
                            ? `url(${props.config.backgroundImage})`
                            : "",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative", // For overlay positioning
                }}
            >
                <img
                    src={props.config.avatar}
                    alt={props.config.title}
                    style={{
                        width: "110px",
                        backgroundColor: "#fff",
                        height: "110px",
                        borderRadius: "50%",
                        border: "4px solid white",
                        position: "absolute",
                        bottom: "-60px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                />
            </header>

            {/* Title and Description */}
            <div
                style={{
                    textAlign: "center",
                    paddingTop: "70px"
                }}
            >
                <Typography variant="h5" sx={{
                    fontWeight: 600,
                }}>
                    {props.config.title}
                </Typography>
                <Typography variant="subtitle1" sx={{
                    fontWeight: 80
                }}>
                    {props.config.description}
                </Typography>
            </div>
        </div>
    );
};