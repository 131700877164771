import { PauseIcon } from "@common/icons/material/Pause";
import { PlayArrowIcon } from "@common/icons/material/PlayArrow";
import Slider from '@mui/material/Slider';
import { Button, IconButton, Menu, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReactPlayer from 'react-player/lazy'
import { VolumeDownIcon } from "@common/icons/material/VolumeDown";
import IconMusic from '../Icons/music-Icons.svg';

export default function HiddenYoutubePlayer(props: {
    url: string
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [playing, setPlaying] = useState(true)
    const [volume, setVolume] = useState(80)

    const togglePause = () => {
        setPlaying(!playing)
    }

    function valuetext(value: number) {
        return `${value}%`;
    }

    return (
        <div>
            <ReactPlayer
                style={{ display: "none" }}
                controls={false}
                playing={playing}
                volume={volume}
                progressInterval={200}
                url={props.url}
                config={{
                    file: {
                        attributes: { preload: "auto" },
                        forceAudio: true,
                    },
                }}
            />
            <div className="transform-none absolute top-1/2 right-10 transform-translate-y-1/2">
                <div>
                    <Button aria-label="delete"
                        id="video-control-player"
                        aria-controls={open ? 'positioned-control' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        color="inherit"
                        variant="contained"
                        size="small"
                        sx={{
                            borderRadius: '50%',
                            padding: 2,
                            minWidth: '18px'
                        }}
                    >
                        <img src={IconMusic} alt="Icon description" className="w-22 h-22" />
                    </Button>
                    <Menu
                        className="overflow-visible"
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        sx={{
                            minWidth: '22px',
                            position: 'absolute'
                        }}
                    >
                        <div className="flex items-center justify-center mb-6 min-w-[20px]">
                        <Stack sx={{ height: 140 }} spacing={1} direction="column">
                                <Slider
                                    orientation="vertical"
                                    getAriaValueText={valuetext}
                                    valueLabelDisplay="auto"
                                    onChange={(component: any) => {
                                        console.log(component.target.value);
                                        setVolume(component.target.value / 100)
                                    }}
                                    defaultValue={80}
                                />
                        </Stack>
                        </div>
                        <div className="flex items-center justify-center">
                            <VolumeDownIcon />
                        </div>

                        <div className="flex items-center justify-center">
                            <IconButton aria-label="delete"
                                id="video-control-player"
                                aria-controls={open ? 'positioned-control' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={togglePause}
                                color="primary"
                                className="bg-primary"
                            >
                                {playing ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                        </div>
                    </Menu>
                </div>
            </div>
        </div>
    );
}
