import { ImageWidget } from '@app/dashboard/biolink/biolink-editor/content/widgets/image-widget/image-widget-dialog';
import { WidgetRendererProps } from '@app/dashboard/biolink/biolink-editor/content/widgets/types/widget-renderer-props';
import clsx from 'clsx';
import { AddCalendarWidget } from './AddCalenderDialog';
import AddToCalendar from './Component/AddCalenderButton';
// import { AddToCalendarButton } from 'add-to-calendar-button-react';

export function AddCalendarWidgetRender({
    widget,
    variant,
}: WidgetRendererProps<AddCalendarWidget>) {
    if (variant === 'editor') {
        // return <div>Date time : {widget.config.time}</div>;
        return <></>
    }

    const calendarEvent = {
        title: widget.config.title ?? "",
        description: widget.config.description ?? "",
        location: widget.config.location ?? "",
        startDate: widget.config.startDate ?? "", // Format ISO 8601
        endDate: widget.config.endDate ?? "",   // Format ISO 8601
    };

    return (
        <div className='flex items-center justify-center'>
            <AddToCalendar event={calendarEvent} />
        </div>
    );
}
