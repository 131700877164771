import { WidgetRendererProps } from "../types/widget-renderer-props";
import { TextEditorWidget } from "./text-widget-dialog";


export function TextEditorWidgetRenderer({
  widget,
  variant,
}: WidgetRendererProps<TextEditorWidget>) {
  if (variant === 'editor') {
    return (
      <div className="text-sm text-muted whitespace-nowrap overflow-hidden">
        {/* <div className="mb-3" dangerouslySetInnerHTML={{ __html: widget.config.content }} /> */}
      </div>
    );
  }

  return (
    <div className="mx-12" dangerouslySetInnerHTML={{ __html: widget.config.title }} />
  );
}
