import {WidgetRendererProps} from '@app/dashboard/biolink/biolink-editor/content/widgets/types/widget-renderer-props';
import {YoutubeWidget} from '@app/dashboard/biolink/biolink-editor/content/widgets/youtube-widget/youtube-widget-dialog';
import getVideoId from 'get-video-id';
import {VideoEmbedWidgetRenderer} from '@app/dashboard/biolink/biolink-editor/content/widgets/video-embed-widget-renderer';
import HiddenYoutubePlayer from './Component/HiddenYoutubePlayer';
import { LinkIcon } from '@common/icons/material/Link';
import MediaPlayer from './Component/MediaPlayer';

export function YoutubeWidgetRenderer({
  widget,
  variant,
}: WidgetRendererProps<YoutubeWidget>) {
  if (!widget.config.url) return null;

  // const {id} = getVideoId(widget.config.url);
  // const embedUrl = `https://www.youtube.com/embed/${id}?${widget.config.backsound ? "autoplay=1" : ""}`;

  if (variant === 'editor') {
    return (
      <div className="text-sm text-muted whitespace-nowrap overflow-hidden">
        <LinkIcon /> <a href={widget.config.url} target='_blank'>{widget.config.url}</a> 
      </div>
    );
  }


  if (widget.config.backsound) {
    return <div className='px-12'>
      <HiddenYoutubePlayer url={widget.config.url} />
    </div>
  }

  // return <VideoEmbedWidgetRenderer variant={variant} embedUrl={embedUrl} />;

  return <div className='px-12'>
    <MediaPlayer url={widget.config.url} />
  </div>
}
