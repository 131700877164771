import { PauseIcon } from "@common/icons/material/Pause";
import { PlayArrowIcon } from "@common/icons/material/PlayArrow";
import Slider from '@mui/material/Slider';
import { Button, IconButton, Menu, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReactPlayer from 'react-player/lazy'
import { VolumeDownIcon } from "@common/icons/material/VolumeDown";
import IconMusic from '../Icons/music-Icons.svg';

export default function MediaPlayer(props: {
    url: string
}) {
    return (
        // <div className="w-4">
            <ReactPlayer
                style={{ display: "block" }}
                controls={true}
                // playing={playing}
                // volume={volume}
                width='100%'
                height='100%'
                progressInterval={200}
                url={props.url}
                config={{
                    file: {
                        attributes: { preload: "auto" },
                        forceAudio: true,
                    },
                }}
            />
        // </div>
    );
}
