import React, { createContext, ReactNode, useContext, useState } from 'react';

// Define the context type
interface CompactLayoutContextType {
    sidebar: ReactNode | null;
    setSidebar: React.Dispatch<React.SetStateAction<ReactNode | null>>;
}

// Create the context with a default value of undefined
const CompactLayoutContext = createContext<CompactLayoutContextType | undefined>(undefined);

// Define provider props
interface CompactLayoutProviderProps {
    children: ReactNode;
}

// Provider Component
function CompactLayoutProvider({ children }: CompactLayoutProviderProps) {
    const [sidebar, setSidebar] = useState<ReactNode | null>(null);

    return (
        <CompactLayoutContext.Provider value={{ sidebar, setSidebar }}>
            {children}
        </CompactLayoutContext.Provider>
    );
}

// Hook to use CompactLayoutContext
function useCompactLayout() {
    const context = useContext(CompactLayoutContext);
    if (!context) {
        throw new Error('useCompactLayout must be used within a CompactLayoutProvider');
    }
    return context;
}

export { CompactLayoutProvider, useCompactLayout };