import {Biolink} from '@app/dashboard/biolink/biolink';
import { BiolinkCompactLayout } from './biolink-compact-layout';
import { CompactLayoutProvider } from '@app/hooks/BiolinkPageHooks';


interface BiolinkRendererProps {
  biolink: Biolink;
}
export function BiolinkRenderer({biolink}: BiolinkRendererProps) {
  return (
    <CompactLayoutProvider>
      <BiolinkCompactLayout
        biolink={biolink}
        enableLinkAnimations
        showAds
        className="max-w-680 mx-auto"
      />
    </CompactLayoutProvider>
  );
}
