import { createSummernoteButton, ReactSummernoteLite } from "@easylogic/react-summernote-lite";
import { memo, useEffect, useRef, useState } from "react";

import "./style.css";

export const DEFAULT_TOOLBAR = [
    ['font', ['bold', 'italic', 'underline', 'clear']],
    ['font', ['fontname', 'fontsize']],
    ['para', ['ul', 'ol', 'paragraph', 'height']],
    ['font', ['strikethrough', 'superscript', 'subscript']],
    ['color', ['color']]
]

export const DEFAULT_POPOVER = {
    image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone', 'floatCenter']],
        ['remove', ['removeMedia']]
    ],
    link: [
        ['link', ['linkDialogShow', 'unlink']]
    ],
    air: [
        ['color', ['color']],
        ['font', ['bold', 'underline', 'clear']],
        // ['para', ['ul', 'paragraph']],
        // ['table', ['table']],
        // ['insert', ['link', 'picture']]
    ]
}

const SummerNote = memo(function SummerNote({
    value = '',
    onChange = (content:any) => { },
    toolbar = DEFAULT_TOOLBAR,
    popover = DEFAULT_POPOVER,
    buttons = {},
    menu = (noteRef:any) => {}
}: any){
    const ref = useRef(0);
    const noteRef = useRef();

    const [isEditorReady, setEditorReady] = useState(false);

    useEffect(() => {
        if (noteRef.current) {
            setEditorReady(true);
        }
    }, [noteRef.current]);

    return(
        <div className = "z-9999 " >
            <ReactSummernoteLite 
                className="block"

                disableDragAndDrop={true} 

                toolbar={toolbar}

                buttons={buttons}

                popover={popover}

                initInvoke={(invoke) => {
                    if (ref.current === 0) {
                        invoke('code', ''); 
                        invoke('pasteHTML', value)
                        invoke('backColor', 'transparent')
                    }
                }}

                onChange={(contents, $editable) => {
                    onChange(contents);
                }}
            />

            {isEditorReady}
        </div >
    );
}, () => true)

export default SummerNote; 