import { FormTextField, TextField } from "@common/ui/forms/input-field/text-field/text-field";
import { useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { FileUploadProvider } from "@common/uploads/uploader/file-upload-provider";
import { FormImageSelector } from "@common/ui/images/image-selector";
import { ColorInput } from "@app/dashboard/biolink/biolink-editor/appearance/color-input";

export default function HeaderCenterEditor({
    field
}: any) {

    const [value, setValue] = useState(JSON.parse(field.value ?? "{}"));

    const handleCoverChange = (e: any) => {
        setValue((prevState: any) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    useEffect(() => {
        field.onChange(JSON.stringify(value));
    }, [value]);

    return (
        <>
            <div className="mb-12">
                <Typography variant="body2">Background : </Typography>

                <RadioGroup
                    row
                    defaultValue={value?.backgroundType ?? "color"}
                    name="backgroundType"
                    onChange={handleCoverChange}
                >
                    <FormControlLabel value="color" control={<Radio />} label="color" />
                    <FormControlLabel value="image" control={<Radio />} label="image" />
                </RadioGroup>


                {
                    value.backgroundType === 'color' ? (
                        <ColorInput
                            label=""
                            value={value.backgroundColor || '#000'}
                            onChange={newValue => {
                                setValue((prevState: any) => ({ ...prevState, backgroundColor: newValue }));
                            }}
                        />
                    ) : (

                        <FileUploadProvider>
                            <FormImageSelector name="backgroundColor" diskPrefix="widgets" onChange={
                                (value) => {
                                    setValue((prevState: any) => ({ ...prevState, backgroundImage: value }));
                                }
                            } />
                        </FileUploadProvider>
                    )
                }
            </div>
            <div className="mb-12">
                <Typography variant="body2">Avatars : </Typography>
                <FileUploadProvider>
                    <FormImageSelector name="avatar"
                        showRemoveButton={true}
                        onChange={
                            (value) => {
                                setValue((prevState: any) => ({ ...prevState, avatar: value }));
                            }
                        } diskPrefix="widgets" />
                </FileUploadProvider>
            </div>
            <div className="mb-12">
                <FormTextField
                    className="mb-24"
                    name="title"
                    label={"Title"}
                    onChange={handleCoverChange}
                />
            </div>
            <div className="mb-12">
                <FormTextField
                    className="mb-24"
                    name="description"
                    label={"Description"}
                    onChange={handleCoverChange}
                />
            </div>
        </>
    );
}