import {ImageWidget} from '@app/dashboard/biolink/biolink-editor/content/widgets/image-widget/image-widget-dialog';
import {WidgetRendererProps} from '@app/dashboard/biolink/biolink-editor/content/widgets/types/widget-renderer-props';
import clsx from 'clsx';
import {ImageIcon} from '@common/icons/material/Image';
import { HeaderWidget } from './header-dialog';
import { json } from 'react-router-dom';
import { HeaderVariant, renderHeader } from './variant/header-varian';
import { CoverConfig } from './variant/cover/converConfig';

export function HeaderWidgetRenderer({
  widget,
  variant,
}: WidgetRendererProps<HeaderWidget>) {
  if (variant === 'editor') {
    return <></>;
  }

  const containerStyle = {
    height: `${widget.config.type}px`,
  };

  const props = {
    config: JSON.parse(widget?.config?.config ?? ""),
    variant: variant
  }

  return (
    <div style={containerStyle}>
      {
        widget.config.type ? (
          renderHeader[widget.config.type as keyof typeof renderHeader](props)
        ) : (
          <div>Select some header type</div>
        )
      
      }
    </div>
  );
}