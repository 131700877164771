import CoverRender from "./cover/converConfig";
import CoverEditor from "./cover/CoverEditor";
import HeaderConfigRender from "./heading-center/headerCentarConfig";
import HeaderCenterEditor from "./heading-center/headerCentarEditor";

import IconCenter from "./heading-center/icons.svg";
import IconCover from "./cover/icons.svg";

export enum HeaderVariant{
    cover = 'cover',
    center = 'center',
}

export interface HeaderVarianInterface {
    // varian: HeaderVariant;
    config: Record<string, string>,
    variant: string
}

export const editorHeader = {
    [HeaderVariant.cover]: (props: { field: any }) => <CoverEditor {...props} />,
    [HeaderVariant.center]: (props: { field: any }) => <HeaderCenterEditor {...props} />
}

export const configHeader = {
    [HeaderVariant.cover]: {
        'preview':  IconCover
    },
    [HeaderVariant.center]: {
        'preview': IconCenter
    }
}

export const renderHeader = {
    [HeaderVariant.cover]: (props: { config: any, variant:any }) => <CoverRender {...props} />,
    [HeaderVariant.center]: (props: { config: any, variant:any }) => <HeaderConfigRender {...props} />,
}