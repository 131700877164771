import { Avatar, Button, IconButton } from "@mui/material";
import { HeaderVarianInterface, HeaderVariant } from "../header-varian";
import Scroll from 'react-scroll';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import './style.css';
import { useEffect, useState } from "react";
import { useIsMobileMediaQuery } from "@common/utils/hooks/is-mobile-media-query";
import { useCompactLayout } from "@app/hooks/BiolinkPageHooks";
import { KeyboardDoubleArrowRightIcon } from "@common/icons/material/KeyboardDoubleArrowRight";
import { KeyboardDoubleArrowLeftIcon } from "@common/icons/material/KeyboardDoubleArrowLeft";

export interface CoverConfig extends HeaderVarianInterface {
    config: {
        'avatar': string,
        'text_heading': string,
        'text': string,
        'backgroundType': string,
        'backgroundColor': string,
        'backgroundImage': string
    };
    variant: string
}

function RenderMobile(props: CoverConfig) {
    const [open, setOpen] = useState<boolean>(false);

    const isMobile = useIsMobileMediaQuery();
    if (isMobile) {
        return <></>;
    }

    return <>
        <div
            style={{
                width: open ? "590px" : "225px",
                backgroundColor: "#ccc",
                transition: "width 0.5s ease",
            }}
        >
        </div>
        <div
            className="z-20 min-w-[600px] hidden md:block absolute right-[370px] rounded-s-lg"
            style={{
                right: !open ? "0px" : "370px",
                transition: "right 0.5s ease",
            }}
        >
            <header
                className="w-full h-full md:h-[90vh] flex flex-col gap-4 relative items-center justify-center rounded-tl-md"
                style={{
                    backgroundColor: props.config.backgroundColor,
                    backgroundImage: (props.config.backgroundType === 'image') ? `url(${props.config.backgroundImage})` : "",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 1px 0px 15px 15px',
                }}
            >
                {
                    props.config?.avatar && <Avatar
                        src={props.config.avatar}
                        sx={{ width: 120, height: 120 }}
                    />
                }
                <div className="rendered-summernote" dangerouslySetInnerHTML={{ __html: props.config?.text_heading ?? "" }} />

                <IconButton
                    onClick={() => setOpen(!open)}
                    className="shadow-md"
                    size="large"
                    sx={{
                        background: '#fff',
                        position: 'absolute',
                        right: '20px',
                        '&:hover': {
                            backgroundColor: '#fff',
                        },
                        opacity: 0.5
                    }}>
                    {!open ? <KeyboardDoubleArrowRightIcon /> : <KeyboardDoubleArrowLeftIcon />}
                </IconButton>
            </header>
        </div>
    </>
}

export default function CoverRender(props: CoverConfig) {
    const isMobile = useIsMobileMediaQuery();

    try {
        const { sidebar, setSidebar } = useCompactLayout();

        useEffect(() => {
            setSidebar(<RenderMobile {...props} />);
        }, []);

        if (!isMobile) {
            return <></>
        }
    } catch (error) {
        console.log("editor mode")
    }

    return (
        <>
            <div className="w-full h-screen flex flex-col gap-4 relative items-center justify-center" style={{
                backgroundColor: props.config.backgroundColor,
                backgroundImage: (props.config.backgroundType === 'image') ? `url(${props.config.backgroundImage})` : "",
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
                {
                    props.config?.avatar && <Avatar
                        src={props.config.avatar}
                        sx={{ width: 120, height: 120 }}
                    />
                }
                <div className="rendered-summernote" dangerouslySetInnerHTML={{ __html: props.config?.text_heading ?? "" }} />
                {/* <div dangerouslySetInnerHTML={{ __html: props.config?.text ?? "" }} /> */}

                <div className="absolute bottom-26">
                    <Scroll.Link to="firstElement" smooth={true} duration={250} containerId="biolink-layout" style={{ display: 'inline-block', margin: '20px' }}>
                        <IconButton className="shadow-md" size="large" sx={{
                            background: '#fff',
                            '&:hover': {
                                backgroundColor: '#',
                            },
                        }}>
                            <KeyboardDoubleArrowDownIcon />
                        </IconButton>
                    </Scroll.Link>
                </div>
            </div>
            <div id="firstElement"></div>
        </>
    );
}