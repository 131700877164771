import { Controller } from "react-hook-form";
import { CoverConfig } from "./converConfig";
import { TextField } from "@common/ui/forms/input-field/text-field/text-field";
import { useEffect, useState } from "react";
import SummerNote from "../../../text-editor-widget/Editor/SummerNote";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { FileUploadProvider } from "@common/uploads/uploader/file-upload-provider";
import { FormImageSelector } from "@common/ui/images/image-selector";
import { FormSelect } from "@common/ui/forms/select/select";
import { ColorInput } from "@app/dashboard/biolink/biolink-editor/appearance/color-input";
import { Item } from "@common/ui/forms/listbox/item";

export const DEFAULT_TOOLBAR = [
    ['font', ['bold', 'italic', 'underline', 'clear']],
    ['font', ['fontname', 'fontsize']],
    ['Insert', ['picture', 'hr']],
    ['para', ['ul', 'ol', 'paragraph', 'height']],
    ['font', ['strikethrough', 'superscript', 'subscript']],
    ['color', ['color']],
    ['view', ['fullscreen', 'codeview']],
]

export default function CoverEditor({
    field
}: any) {

    const [value, setValue] = useState(JSON.parse(field.value ?? "{}"));

    const handleCoverChange = (e : any) => {
        setValue((prevState : any) => ({...prevState, [e.target.name] : e.target.value }));
    };

    useEffect(() => {
        field.onChange(JSON.stringify(value));
    }, [value]);

    return (
        <>
            <div className="mb-12">
                <Typography variant="body2">Content : </Typography>
                <SummerNote
                    toolbar={DEFAULT_TOOLBAR}
                    value={value.text_heading ?? "<p>Text Header Content ...</p>"}
                    onChange={(value: string) => {
                        setValue((prevState: any) => ({ ...prevState, text_heading: value }));
                    }}
                />
            </div>
            <div className="mb-12">
                <Typography variant="body2">Avatars : </Typography>
                <FileUploadProvider>
                    <FormImageSelector name="avatar" 
                    showRemoveButton={true}
                    onChange={
                        (value) => {
                            setValue((prevState: any) => ({ ...prevState, avatar: value }));
                        }
                    } diskPrefix="widgets"/>
                </FileUploadProvider>
            </div>
            {/* <div className="mb-12">
                <Typography variant="body2">Text Footer : </Typography>
                <SummerNote
                    value={value.text ?? "<p>Text Footer Content ...</p>"}
                    onChange={(value: string) => {
                        setValue((prevState: any) => ({ ...prevState, text: value }));
                    }}
                />
            </div> */}
            <div className="mb-12">
                <Typography variant="body2">Background : </Typography>
                
                <RadioGroup
                    row
                    defaultValue={value?.backgroundType ?? "color"}
                    name="backgroundType"
                    onChange={handleCoverChange}
                >
                    <FormControlLabel value="color" control={<Radio />} label="color" />
                    <FormControlLabel value="image" control={<Radio />} label="image" />
                </RadioGroup>


                {
                    value.backgroundType === 'color' ? (
                        <ColorInput
                            label=""
                            value={value.backgroundColor || '#000'}
                            onChange={newValue => {
                                setValue((prevState: any) => ({ ...prevState, backgroundColor: newValue }));
                            }}
                        />
                    ):(

                        <FileUploadProvider>
                                <FormImageSelector name="backgroundColor" diskPrefix="widgets" onChange={
                                    (value) => {
                                        setValue((prevState: any) => ({ ...prevState, backgroundImage: value }));
                                    }
                                } />
                        </FileUploadProvider>
                    )
                }
            </div>
        </>
    );
}